@tailwind base;
@tailwind components;
@tailwind utilities;

/**
* Rich Text Input
*/
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6,
.ql-editor ol,
.ql-editor p,
.ql-editor ul {
    margin-bottom: 1rem !important;
}

@layer components {
    .dual-range-slider {
        width: 100%;
        height: 42px;
        position: relative;
        margin-bottom: 4px;
    }

    .dual-range-slider__track {
        width: 100%;
        height: 8px;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 8px;
        background-color: #e5e7eb;
    }

    .dual-range-slider__track--active {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(0);
        border-radius: 8px;
        background-color: #017cef;
    }

    .dual-range-slider__dividers {
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dual-range-slider__divider {
        width: 1px;
        height: 6px;
        background-color: white;
    }

    .dual-range-slider__min-value,
    .dual-range-slider__max-value {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 10px;
        line-height: 18px;
        font-weight: 600;
        text-align: center;
        display: inline-flex;
        padding: 3px 5px;
        color: #09090b;
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }

    .dual-range-slider__input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        outline: none;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: transparent;
        pointer-events: none;
    }

    .dual-range-slider__input::-webkit-slider-runnable-track {
        -webkit-appearance: none !important;
        height: 8px !important;
        background: transparent !important;
    }

    .dual-range-slider__input::-webkit-slider-thumb {
        -webkit-appearance: none !important;
        height: 16px !important;
        width: 16px !important;
        background-color: #017cef !important;
        cursor: pointer !important;
        margin-top: -4px !important;
        pointer-events: auto !important;
        border-radius: 50% !important;
        transition: background-color 0.15s ease-in-out !important;
    }

    .dual-range-slider__input:active::-webkit-slider-thumb {
        background-color: #ffffff !important;
        border: 1px solid #017cef !important;
    }

    .dual-range-slider__input::-moz-range-track {
        -moz-appearance: none !important;
        background: transparent !important;
        height: 8px;
    }

    .dual-range-slider__input::-ms-track {
        appearance: none !important;
        background: transparent !important;
        height: 8px !important;
    }

    .dual-range-slider__input::-moz-range-thumb {
        -webkit-appearance: none !important;
        height: 16px !important;
        width: 16px !important;
        cursor: pointer !important;
        border-radius: 50% !important;
        background-color: #017cef !important;
        pointer-events: auto !important;
        border: none !important;
        transform: translateY(5px) !important;
    }

    .dual-range-slider__input::-ms-thumb {
        appearance: none !important;
        height: 16px !important;
        width: 16px !important;
        cursor: pointer !important;
        border-radius: 50% !important;
        background-color: #017cef !important;
        pointer-events: auto !important;
    }
}
